import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import Title from '@components/Title';
import { useSearchDESByIndividualId } from '@repositories/districts';
import { getClubDisplayName } from '@utils/club-name';
import { handleGoToClub } from '@utils/getClubDestination';
import { getUserClubs } from '@utils/getUserClubs';
import { localizedNavigate } from '@utils/localized-navigate';
import fetchMyClubsQuery from '@queries/fetchMyClubsQuery';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import {
  ClubAffiliation,
  FetchMyClubsQuery,
  FetchMyClubsQueryVariables,
} from '@typings/operations';

const useMyClubsQuery = () =>
  useLazyQuery<FetchMyClubsQuery, FetchMyClubsQueryVariables>(
    fetchMyClubsQuery
  );

const MyClubs = () => {
  const { t } = useTranslation();
  const { user } = useAppConfig();
  const [fetchMyClubs, { data, loading }] = useMyClubsQuery();
  const [
    fetchSearchDES,
    { data: searchDESData, loading: searchDESLoading },
  ] = useSearchDESByIndividualId();

  const clubsAffiliation =
    (data?.getIndividual?.clubAffiliations as ClubAffiliation[]) || [];

  const myClubs = getUserClubs(clubsAffiliation);

  useEffect(() => {
    if (user?.individualId) {
      fetchMyClubs({
        variables: {
          individualId: user.individualId,
        },
      });
      fetchSearchDES({
        variables: {
          individualId: user.individualId,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (data && searchDESData) {
      const clubsData = myClubs;
      // If there is only one club affiliation => redirect right to the club page
      if (clubsData?.length === 1) {
        handleGoToClub(clubsData[0].clubId);
      }

      if (clubsData?.length === 0) {
        localizedNavigate('/club-search');
      }
    }
  }, [data, searchDESData]);

  if (loading || searchDESLoading) {
    return <Loading />;
  }

  const clubsData = (myClubs as ClubAffiliation[]) || [];

  return (
    <OneColumn className="mb-20">
      <Title>{t('my-clubs.title', 'My Clubs')}</Title>
      {clubsData
        ? clubsData.map(({ clubId, clubFullName, clubType }) => (
            <div key={clubId}>
              <span
                onClick={() => handleGoToClub(clubId)}
                className="link-styles"
              >
                {getClubDisplayName(clubFullName, clubType, t)}
              </span>
            </div>
          ))
        : t('my-clubs.empty-list', 'You are not affiliated with any clubs')}
    </OneColumn>
  );
};

export default MyClubs;
