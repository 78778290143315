import * as React from 'react';

import MyClubsPage from '@components/Pages/MyClubs';

import RequireLogin from '../components/Auth/RequireLogin';

const MyClubs: React.FC = () => {
  return (
    <RequireLogin>
      <MyClubsPage />
    </RequireLogin>
  );
};

export default MyClubs;
