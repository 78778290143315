import { gql } from '@apollo/client';

export default gql`
  query fetchMyClubs($individualId: String!) {
    getIndividual(id: $individualId) {
      clubAffiliations {
        clubId
        clubFullName
        clubType
        affiliations {
          ... on LeadershipAffiliation {
            leadershipRole
          }
          ... on MembershipAffiliation {
            membershipType
          }
        }
      }
    }
  }
`;
